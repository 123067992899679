/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import banner from "../../static/imgs/heartfulness-green-donation-banner.jpg";

const HeartfulnessCanadaDonation = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Canada Donation";
    return `<gatsby_donation donationId = "${id}" btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Heartfulness Canada Donation" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_Canada_Donation", event),
    []
  );

  return (
    <>
      <DonationLayout
        seoData={{
          title: "Heartfulness Donations",
        }}
      >
        <Box
          sx={{
            minHeight: "560px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${banner})`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ paddingBottom: "30px" }}>
            <h2
              sx={{
                color: "White",
                marginTop: "30px",
                fontWeight: "500",
                lineHeight: "1.2",
                fontSize: "55px",
                marginBottom: "1rem",
                textAlign: "center!important",
              }}
            >
              Heartfulness Canada Donations
            </h2>
            <PageContent
              eventCallback={eventCallback}
              pageContent={pageContent(13, "Contribute Now")}
            />
          </Box>
        </Box>
        <Grid
          columns={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "20px 74px 30px 74px",
          }}
        >
          <Box>
            <h3
              sx={{
                marginTop: "15px",
                fontWeight: "500",
                lineHeight: "1.5",
                fontSize: "calc(1.3rem + .6vw)",
                marginBottom: "0.5rem",
              }}
            >
              General donations to Heartfulness Institute, Canada
            </h3>
            <p
              sx={{
                fontSize: "18px",
                marginBottom: "1rem",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              <b>
                We need your financial support to help us touch many more
                hearts.
              </b>
            </p>
            <p
              sx={{
                fontSize: "18px",
                marginBottom: "1rem",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              Share in the work of bringing peace, compassion, happiness and
              harmony to people in all walks of life by making a financial gift
              to Heartfulness Institute, Canada.
            </p>
            <p
              sx={{
                fontSize: "18px",
                marginBottom: "1rem",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              Donations of any size make it possible for us to change lives by
              teaching and promoting Heartfulness techniques. These programs are
              led and conducted by volunteers and are taught free of charge to
              participants. Our teams of dedicated volunteers donate their time
              and talents, often in their professional fields, and regularly
              cover their own expenses.
            </p>
            <p
              sx={{
                fontSize: "18px",
                marginBottom: "1rem",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              Your donation will support our programs in schools, universities
              and colleges, corporations, and hospitals, as well as any
              interested groups and communities.
            </p>
          </Box>
          <Box>
            <PageContent
              eventCallback={eventCallback}
              pageContent={pageContent(13, "Contribute Now")}
            />
          </Box>
          <Box>
            <h3
              sx={{
                fontWeight: "500",
                lineHeight: "1.5",
                fontSize: "calc(1.3rem + .6vw)",
                marginBottom: "0.5rem",
              }}
            >
              A note about donations received in Canada:
            </h3>
            <p
              sx={{
                fontSize: "18px",
                marginBottom: "1rem",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              All donations made are voluntary and are suggested to be made to
              the general fund of Heartfulness Institute. General funds are used
              for projects in areas of COVID-19 care, the environment and green
              initiatives, positive lifestyle, and sustainability initiatives,
              among others.
            </p>
            <p
              sx={{
                fontSize: "18px",
                marginBottom: "1rem",
                fontWeight: "500",
                lineHeight: "1.5",
              }}
            >
              If you would like to make a gift for a specific purpose, please
              contact the Treasurer at{" "}
              <a href="ca.treasurer@srcm.org">ca.treasurer@srcm.org</a>{" "}
              beforehand with your request.
            </p>
          </Box>
        </Grid>
      </DonationLayout>
    </>
  );
};

export default HeartfulnessCanadaDonation;
